import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import BPHeader from './TPCOMPONENTS/bp-header'
import BPSideMenu from './TPCOMPONENTS/bp-sidemenu'
import { FinancialActions } from './TPCOMPONENTS/bp-reusables'
import { UserInterfaceContext } from '../Contexts/ui-context'
import { Loading } from '../Components/reusables'
import { Alert } from '../Components/reusables'
function BPAddExpenses() {

    const {Progress, AlertNotice,setLoading} = useContext(UserInterfaceContext) 
  
  const [academicYear, setAcademicYear] =useState([])
  const [academicTerm, setAcademicTerm] =useState([])

  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState('')
  const [name, setName] =useState('')
  const [telephone, setTelephone] =useState('')
  const [amount, setAmount] =useState('')
  const [paymentMethod, setPaymentMethod] =useState('')
  const [expenseType, setExpenseType] =useState('')
  const [dateOfExpense, setDateOfExpense] =useState('')
  const [description, setDescription] =useState('')

const expenseData = {
  academicYear:selectedAcademicYear,
  academicTerm: selectedAcademicTerm,
  expenseType : expenseType,
  name : name,
  telephone: telephone,
  amount : amount,
  paymentMethod : paymentMethod,
  dateOfExpense : dateOfExpense,
  description : description,

}

function ResetInputs (){
  setSelectedAcademicTerm('')
  setSelectedAcademicYear('')
  setAmount('')
  setAmount('')
  setTelephone('')
  setPaymentMethod('')
  setExpenseType('')
  setDateOfExpense('')
  setDescription('')

}


const fetchAcademicYear = async () => {
  try {
    Progress('30%');
    const academicYearResponse = await axios.get('https://new-softesc-backend.onrender.com/academicYear', {
     
    });
    //console.log(academicYearResponse);
    setAcademicYear(academicYearResponse.data);
    Progress('100%');
  } catch (error) {
    console.error(error);
    Progress('0%');
  }
};


const fetchAcademicTerm = async () => {
  try {
    //console.log('selected academic year', selectedAcademicYear)
    Progress('70%');
    const academicTermResponse = await axios.get('https://new-softesc-backend.onrender.com/academicTerm',{
      params:{
          academicYearName : selectedAcademicYear,
      }
    });
    //console.log(academicTermResponse);
    setAcademicTerm(academicTermResponse.data);
    Progress('100%');
  } catch (error) {
    console.error(error);
    Progress('0%');
  }
};

useEffect(() => {
  fetchAcademicTerm();
  fetchAcademicYear()
}, [selectedAcademicYear]);


function handleSubmitExpense(e) {
  e.preventDefault()
  if(expenseData.academicYear!=='' && expenseData.academicTerm !=='' && expenseData.amount !=='' && expenseData.expenseType !=='' && expenseData.paymentMethod !=='' && expenseData.dateOfExpense!==''){
    if(expenseData.amount < 0){
      AlertNotice('Enter a positive amount value', false)
      return
    }
    setLoading(true)
    Progress('30%')

  //console.log('processing expenses submission');
  // axios.post('https://new-softesc-backend.onrender.com/finance/expense', expenseData)
  axios.post('https://new-softesc-backend.onrender.com/finance/expense', expenseData)
    .then((response) => {
      AlertNotice(response.data.message, true)
      Progress('100%')
      ResetInputs()
      setLoading(false)
    })
    .catch((error) => {
      AlertNotice(error.response.data.error, false)
      Progress('0%')
      setLoading(false)
    });
  }
  else {
    AlertNotice('Complete all Required fields ', false)
    return
  }

  
}

  

  return (
    <div className='App'>
      <BPSideMenu/>
      <Alert/>
      <div className='main-menu'>
        <BPHeader/>
        <div  className='content-loader add-income'>
            <FinancialActions/>
            {/* the main menu content goes here  */}
      <div className="add-income-body sorting" style={{position:"relative"}}>
        <Loading/>
        <h2>Add new Expense</h2>
        <div className="sorting-body">

          {/* academic year  */}
        <div className="input">
          <label htmlFor="">Academic Year: </label>
          <select 
            name="" 
            id=""
            value={selectedAcademicYear}
            onChange={(e)=>{setSelectedAcademicYear(e.target.value)}}

            >
            <option value="" disabled hidden >select an academic year</option>
            {academicYear.map((year, index)=>(
                  <option key={index} value={year.academicYearName}>{year.academicYearName}</option>

            ))}            

          </select>
          

        </div>

        {/* academic term  */}
        <div className="input">
          <label htmlFor="">Academic Term: </label>
          <select 
            name="" 
            id=""
            value={selectedAcademicTerm}
            onChange={(e)=>{setSelectedAcademicTerm(e.target.value)}}
            >
            <option value="" disabled hidden>select an academic term</option>
            {academicTerm.map((term, index)=>(
                  <option key={index} value={term.refName}>{term.academicTermName}</option>

            ))}
          </select>
          
          
        </div>

        {/* type of expense  */}
        <div className="input">
        <label htmlFor="">Type of Expense: </label>
        <select 
          id="mySelect" 
          value={expenseType}
          onChange={(e)=> {setExpenseType(e.target.value)}}
          >
                <option value="" disabled hidden>Select An Expense </option>
                <option value="salary">Salary </option>
                <option value="snnit">SNNIT TIER 2 </option>
                <option value="gre">GRE - TAX </option>
                <option value="waterbill">Water Bill</option>
                <option value="electricity-bill">Electricity Bill Fees</option>
                <option value="bookstationary">Books and Stationary</option>
                <option value="maintenance">Maintaince</option>
                <option value="transportation">Transportation</option>
                <option value="canteen">Canteen</option>
                <option value="others">Others</option>
                
                {/* <option value="option2">Transportation</option> */}
                {/* <option value="option2">Transportation</option> */}
            </select>        
        </div>
               
        {/* amount  */}
        <div className="input">
        <label htmlFor="">Amount: </label>
        <input 
          type="number" 
          min={0} 
          placeholder='Enter the amont '
          value={amount}
          onChange={(e)=> {setAmount(e.target.value)}}
          />
        </div>

        {/* payment method  */}
        <div className="input">
        <label htmlFor="">Payment Method: </label>
        <select 
          id="mySelect" 
          value={paymentMethod}
          onChange={(e)=> {setPaymentMethod(e.target.value)}}
          >
                <option value="" disabled selected>Cash / Momo / Bank </option>
                <option value="cash">Cash </option>
                <option value="bank">Bank Cheque </option>
                <option value="momo">Momo </option>
                <option value="creditcard">Credit Card </option>
                
                {/* <option value="option2">Transportation</option> */}
                {/* <option value="option2">Transportation</option> */}
            </select> 
        </div>

        {/* date  */}
       <div className="input">
       <label htmlFor="">Date: </label>
        <input 
          type="date"
          value={dateOfExpense}
          onChange={(e)=> {setDateOfExpense(e.target.value)}}
          />
       </div>

        {/* description  */}
        <div className="input" style={{minWidth: '70%'}}>
        <label htmlFor="">Description: </label>
        <textarea 
          name="" 
          id="" 
          cols="30" 
          rows="10"
          value={description}
          onChange={(e)=> {setDescription(e.target.value)}}
          ></textarea>

        </div>
        </div>
        <div id="submit-reset-buttons">
        <button onClick={handleSubmitExpense}>Submit</button>
        <button onCanPlayThrough={ResetInputs}>Reset inputs</button>

      </div>
      </div>
              
      </div>

      </div>
    </div>
  )
}

export default BPAddExpenses
